import {unescapeHtml} from "../../../../utils/StringUtilities";
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ErrorBlock from "../ErrorBlock";
import ClassicNewsBlock from "./ClassicNewsBlock";
import SchoolFeedBlock from "./SchoolFeedBlock";
import ClickableLink from "../../../utilities/ClickableLink";
import {IBlockComponentProps} from "../_Block";

export interface INewsBlockProps extends IBlockComponentProps {
    blockObj: INewsBlockObj,
    blockExpanded: boolean,
}

export default function NewsBlock(props: INewsBlockProps) {
    try {
        const {title, blockType, organizationUrl, displayAsContent} = props.blockObj;

        return <BlockScroller className="sb-block-news"
                              blockRef={props.blockRef}
                              blockObj={props.blockObj}
                              htmlId={props.htmlId}
                              setShowExpandButton={props.setShowExpandButton}
                              outsideGrid={props.outsideGrid}>
            <BlockHead className="sb-block-news" blockType={blockType} blockId={props.blockObj.id}>
                {displayAsContent ? <span>{title}</span> : <ClickableLink href={organizationUrl} style={{"position": "static"}}>
                    <span>{unescapeHtml(title) || "News"}</span>
                </ClickableLink>}
            </BlockHead>
            <div className="sb-blockContent">
                {displayAsContent ? <SchoolFeedBlock {...props} /> : <ClassicNewsBlock {...props} />}
            </div>
        </BlockScroller>
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
}
