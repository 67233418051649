import { useContext, FC } from 'react';
import {StoreContext} from "../../../../stores/StoreLoader";
import {getLogoHtml} from "../blockUtils";
import {unescapeHtml} from "../../../../utils/StringUtilities";
import {INewsBlockProps} from "./NewsBlock";

const ClassicNewsBlock: FC<INewsBlockProps> = (props) => {
    const {modalStore} = useContext(StoreContext);
    const subBlocks: INewsSubBlock[] = props.blockObj.subBlocks;

    return <ul className="sb-section-list">
        {subBlocks && subBlocks.map(block => {
            let colorStyle = {}, colorClass = "", logoOrImage;
            if (!block.branding.color) {
                block.branding.color = "#FF6600";
            }
            switch (block.branding.color) {
                case "orgColorDark":
                    colorClass = "sb-organization-color-block-bg";
                    break;

                case "orgColorLight":
                    colorClass = "sb-organization-color-block-font";
                    break;

                default:
                    colorStyle = {"backgroundColor": block.branding.color, color: "#fff"};
            }

            if (!block.branding.logo) {
                block.branding.logo = {
                    fontawesome: "far fa-circle",
                };
            }

            if (!block.picture) {
                if (block.branding.logo.fontawesome) {
                    // this is here because the fontawesome logos are 2x when viewing a section and 1x in other areas.
                    logoOrImage =
                        <span className={`fa-2x ${block.branding.logo.fontawesome}`} role="img"
                              aria-label={block.branding.arialabel}/>;
                } else {
                    logoOrImage = getLogoHtml(block);
                }
            } else {
                logoOrImage =
                    <img style={{"height": "100%"}} src={block.picture} alt={block.title} title={block.title}/>;
            }

            /* Assigned fixed width and height for all blocks */
            block.sizeX = 3;
            block.sizeY = 3;

            function handleClick() {
                modalStore.addModal({
                    type: "block",
                    itemId: block.model.id,
                })
            }

            return <li className="sb-news-item" data-block-id={block.model.id}
                       data-blocktype={block.type} key={block.model.id}>
                <button onClick={handleClick}>
                    <div className="clearfix">
                        {props.blockObj.sizeX > 1 && <div className={`sb-section-item-picture sb-img-icon clearfix ${colorClass}`}
                                                          style={colorStyle}>
                            {logoOrImage}
                        </div>}
                        <span>{unescapeHtml(block.title)}</span>
                        <p className="sb-news-item-read-more">
                            {(() => {
                                switch (block.type) {
                                    case 'sharedfiles':
                                        return "view file/folder";
                                        break;

                                    case 'eventlist':
                                        return "view events";
                                        break;

                                    case 'section':
                                        return "view section";
                                        break;

                                    case 'poll':
                                        return "view poll";
                                        break;

                                    case 'quicklink':
                                        return "view links";
                                        break;

                                    case 'video':
                                        return "watch video";
                                        break;

                                    // case 'group':
                                    //     if(Yii::app()->params['organization']->type == "district") {
                                    //     return "view department";
                                    // } else {
                                    //     return "view group";
                                    // }
                                    //     break;

                                    default:
                                        return "read more";
                                }
                            })()}
                        </p>
                    </div>
                </button>
            </li>
        })}
    </ul>
}

export default ClassicNewsBlock;
